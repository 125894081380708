<template>
    <div>
        <div class="videos md:flex">
            <nav class="bg-secondary-600 to-md:py-6 text-white md::w-1/3 md:max-w-[16rem]">
                <form class="md:block md:sticky transition-top ease-in-out duration-300" :class="isMenuOpen ? 'md:top-28' : 'md:top-0'" @submit.prevent="onFormSubmit">
                    <div class="pr-6 md:py-6 border-b border-secondary-500">
                        <div class="flex items-center bg-primary-500">
                            <div class="w-14">
                                <search-icon class="block text-black w-4 h-4 mx-auto" />
                            </div>

                            <div class="flex-1">
                                <input
                                    v-model="search"
                                    type="search"
                                    name="search"
                                    class="block w-full appearance-none bg-transparent text-black font-semibold pl-0 pr-4 py-3 border-0 placeholder-black placeholder-opacity-100 focus:ring-0 focus:outline-none"
                                    placeholder="SEARCH"
                                />
                            </div>
                        </div>
                    </div>

                    <div v-if="filter.category" class="px-6 mt-6 mb-2">
                        <button
                            class="flex items-center w-full px-4 py-2 text-black bg-primary-500 font-bold text-sm text-left"
                            @click.prevent="filter.category = null"
                        >
                            <span class="flex-1">{{ categoryName }}</span>
                            <x-mark class="block w-4 h-4" />
                        </button>
                    </div>

                    <div v-if="filter.contributor" class="px-6 mt-6 mb-2">
                        <button
                            class="flex items-center w-full px-4 py-2 text-black bg-primary-500 font-bold text-sm text-left"
                            @click.prevent="filter.contributor = null"
                        >
                            <span class="flex-1">{{ contributorName }}</span>
                            <x-mark class="block w-4 h-4" />
                        </button>
                    </div>

                    <div v-for="filterItem in filters" :key="filterItem.handle" class="py-3 border-b border-secondary-500">
                        <button type="button" class="flex w-full items-center text-left px-6" @click.prevent="expanded[filterItem.handle] = !expanded[filterItem.handle]">
                            <span class="block uppercase font-semibold flex-1">{{ filterItem.label }}</span>
                            <span
                                class="text-primary-500 transform transition-transform duration-300 ease-in-out"
                                :class="{ '-rotate-180': expanded[filterItem.handle] }"
                                aria-hidden="true"
                            >
                                <caret-down-icon class="block w-4 h-auto" />
                            </span>
                        </button>

                        <collapse-transition>
                            <div v-show="expanded[filterItem.handle]">
                                <ul class="pt-2 px-6">
                                    <li v-for="option in filterItem.values" :key="option.value" class="filter-radio">
                                        <input
                                            :id="`filter-${filterItem.handle}-${option.value}`"
                                            v-model="filter[filterItem.handle]"
                                            :value="option.value"
                                            type="checkbox"
                                            class="filter__checkbox"
                                        />
                                        <label class="filter__label" :for="`filter-${filterItem.handle}-${option.value}`">{{ option.label }}</label>
                                    </li>
                                </ul>
                            </div>
                        </collapse-transition>
                    </div>

                    <div class="to-md:hidden p-6">
                        <div class="p-4 bg-secondary-500">
                            <h2 class="uppercase font-semibold text-base mb-2">Have a question?</h2>
                            <p class="text-xs">If you have a specific question you'd like us to consider.</p>

                            <div class="mt-4 text-right">
                                <button type="button" class="btn btn--primary -mr-4" @click.prevent="onAskQuestion">Ask Us</button>
                            </div>
                        </div>
                    </div>
                </form>
            </nav>

            <section class="flex-1 p-4 md:p-6">
                <transition-group
                    name="videos"
                    tag="div"
                    class="grid gap-4 md:gap-2 grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 grid-flow-row auto-rows-max"
                    @before-leave="onBeforeLeave"
                    @after-leave="onAfterLeave"
                >
                    <video-item
                        v-for="(video, index) in videos"
                        :key="`video-item-${video.id}`"
                        :video="video"
                        class="videos-item"
                        :style="{ order: index + 1 }"
                        @play="onSelectVideo(video, index)"
                    />

                    <div
                        v-if="selectedVideo"
                        :key="'video-player'"
                        class="col-span-2 xl:col-span-3 2xl:col-span-4 overflow-hidden"
                        :style="{ order: selectedVideoOrder }"
                    >
                        <div class="block">
                            <div class="aspect aspect-16x9">
                                <iframe
                                    :src="`https://www.youtube.com/embed/${youtubeId}`"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
                                    class="block w-full h-full"
                                />
                            </div>

                            <div class="flex to-lg:flex-wrap items-center p-6 md:px-12 md:py-8 bg-secondary-500">
                                <div class="to-md:hidden">
                                    <Bubbles class="w-18 h-auto text-primary-500" />
                                </div>

                                <div class="flex-1 md:ml-8">
                                    <h2 class="text-base md:text-2xl text-white">{{ selectedVideo.title }}</h2>
                                    <p class="text-xs md:text-base text-white">
                                        <span class="block md:inline-block md:pr-4 md:mr-4 md:border-r border-white last:border-r-0 last:mr-0">
                                            <span class="font-semibold">{{ selectedName }}</span> - {{ selectedPosition }}
                                        </span>

                                        <span class="block mt-2 md:inline-block md:pr-4 md:mt-0 md:mr-4 md:border-r border-white last:border-r-0 last:mr-0">
                                            <template v-if="!interviewer">
                                                <span class="font-semibold">Peter Crumpton</span> - Heart Transplant @ 24
                                            </template>

                                            <template v-else>
                                                <span class="font-semibold">{{ interviewer.title }}</span> - {{ interviewer.description }}
                                            </template>
                                        </span>
                                    </p>
                                </div>

                                <div class="flex lg:pl-4 to-lg:w-full to-lg:text-right to-lg:mt-4">
                                    <a
                                        href="/membership"
                                        class="btn btn--primary mr-4"
                                    >
                                        Subscribe
                                    </a>

                                    <button
                                        v-if="!showShare"
                                        type="button"
                                        class="btn btn--secondary"
                                        @click.prevent="shareVideo"
                                    >
                                        Share
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition-group>
            </section>
        </div>

        <section class="py-30 bg-gradient-to-b from-secondary-600 to-secondary-700">
            <div class="container text-center">
                <button type="button" class="btn btn--secondary" @click.prevent="onSubscribe">
                    Subscribe
                </button>
            </div>
        </section>
    </div>
</template>

<script>
    import { parse, stringify } from 'qs';
    import Bubbles from '../../img/bubbles.svg';
    import SearchIcon from '../../img/icons/search.svg';
    import CaretDownIcon from '../../img/icons/caret-down.svg';
    import XMark from '../../img/icons/xmark-solid.svg';
    import CollapseTransition from './CollapseTransition.vue';
    import VideoItem from './Video.vue';
    import { GET_VIDEOS_QUERY } from '../graphql/videos';
    import 'vue-plyr/dist/vue-plyr.css';

    export default {
        components: {
            CollapseTransition,
            VideoItem,
            Bubbles,
            SearchIcon,
            CaretDownIcon,
            XMark,
        },

        props: {
            filters: {
                type: Array,
                required: true,
            },
            categoryName: {
                type: String,
                default: null,
            },
            contributorName: {
                type: String,
                default: null,
            },
        },

        data() {
            const query = parse(location.search, {
                ignoreQueryPrefix: true,
            });

            const expanded = {};
            const filter = {
                category: query.category,
                contributor: query.contributor,
            };

            for (const filterItem of this.filters) {
                filter[filterItem.handle] = query.filter && query.filter[filterItem.handle] ? query.filter[filterItem.handle] : [];
                expanded[filterItem.handle] = window.innerWidth > 768;
            }

            return {
                search: query.search || '',
                filter,
                expanded,
                videos: [],
                showShare: false,
                selectedVideo: null,
                selectedVideoEmbed: null,
                selectedVideoOrder: 1,
                isMenuOpen: false,
            };
        },

        computed: {
            youtubeId() {
                const regexp = /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/gi;
                const match = regexp.exec(this.selectedVideo.videoUrl);
                if (match) {

                    return match[1];
                }

                return null;
            },
            selectedName() {
                return this.selectedVideo.description.split('\n')[0];
            },
            selectedPosition() {
                return this.selectedVideo.description.split('\n')[1];
            },
            interviewer() {
                if (!this.selectedVideo) {
                    return null;
                }

                const [interviewer] = this.selectedVideo.interviewer;
                return interviewer || null;
            },
        },

        watch: {
            filter: {
                deep: true,
                handler() {
                    this.selectedVideo = null;
                    this.selectedVideoEmbed = null;

                    const params = {
                        filter: {},
                    };

                    for (const key in this.filter) {
                        if (Object.prototype.hasOwnProperty.call(this.filter, key) && this.filter[key] !== null) {
                            params.filter[key] = this.filter[key];
                        }
                    }

                    if (this.search.trim()) {
                        params.search = this.search.trim();
                    }

                    const url = stringify(params, {
                        encodeValuesOnly: true,
                        arrayFormat: 'brackets',
                    });
                    history.replaceState(null, '', url ? `?${url}` : location.pathname);
                },
            },
        },

        mounted() {
            this.emitter.on('menu-floating', (isOpen) => {
                this.isMenuOpen = isOpen;
            });
        },

        methods: {
            onBeforeLeave(element) {
                const width = element.offsetWidth;
                const height = element.offsetHeight;

                element.style.width = `${width}px`;
                element.style.height = `${height}px`;
            },
            onAfterLeave(element) {
                element.style.removeProperty('width');
                element.style.removeProperty('height');
            },
            async onSelectVideo(video, index) {
                this.selectedVideo = null;
                this.selectedVideoEmbed = null;
                this.showShare = false;

                await this.$nextTick();

                const normalIndex = index + 1;
                let cols = 2;

                if (window.innerWidth > 1536) {
                    cols = 4;
                } else if (window.innerWidth > 1280) {
                    cols = 3;
                } else if (window.innerWidth > 768) {
                    cols = 2;
                }

                let diff = (normalIndex % cols);
                if (diff === 0) {
                    diff = cols;
                }

                this.selectedVideoOrder = normalIndex - diff + cols;
                this.selectedVideo = video;
            },
            async onFormSubmit() {
                await this.$apollo.queries.videos.refresh();
            },
            onSubscribe() {
                this.emitter.emit('open-subscribe');
            },
            onAskQuestion() {
                this.emitter.emit('open-ask-question');
            },
            shareVideo() {
                navigator.share({
                    title: this.selectedVideo.title,
                    url: this.selectedVideo.videoUrl,
                });
            },
        },

        apollo: {
            videos: {
                query: GET_VIDEOS_QUERY,
                fetchPolicy: 'cache-and-network',
                debounce: 500,
                variables() {
                    const vars = {
                        ...this.filter,
                    };

                    if (vars.category) {
                        vars.category = [{
                            slug: vars.category,
                        }];
                    } else {
                        delete vars.category;
                    }

                    if (vars.contributor) {
                        vars.contributor = [{
                            slug: vars.contributor,
                        }];
                    } else {
                        delete vars.contributor;
                    }

                    if (this.search.trim() !== '') {
                        vars.search = this.search.trim();
                    }

                    return vars;
                },
            },
        },
    };
</script>

<style lang="postcss">
    .videos {
        @screen md {
            min-height: calc(100vh - theme('spacing.28'));
        }
    }

    .filter {
        &__checkbox {
            @apply sr-only;
        }

        &__label {
            @apply cursor-pointer relative pl-6;

            &::before {
                @apply block absolute -left-0 top-1 w-4 h-4 rounded-sm border-2 border-white;
                content: '';
            }
        }

        &__checkbox:checked + &__label {
            @apply text-primary-500;

            &::before {
                @apply bg-primary-500 border-primary-500;
            }

            &::after {
                @apply block absolute left-0.5 top-1.5 w-3 h-3 z-1 bg-no-repeat bg-contain bg-center;
                background-image: svg-load('img/icons/tick.svg', fill='#344E51');
                content: '';
            }
        }
    }

    .videos-item {
        @apply inline-block;
        transition: all 0.3s ease;
    }

    .videos-enter-from,
    .videos-leave-to {
        @apply opacity-0;
        transform: translateY(20px);
    }

    .videos-leave-active {
        @apply absolute;
    }
</style>
